.App {
  text-align: center;
  height: calc(100vh - 30px);
  margin-top: 40px;
  font-size: 2em;
  line-height: 1em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 770) {
  .App {
    padding-top: 40px;
  }
}

@media screen and (max-width: 770px) {
  .App {
    padding-top: 80px;
    font-size: 1.3em !important;
    line-height: 1em;
  }

  h1 {
    font-size: 1.2em !important;
  }

  h2 {
    font-size: 1.2em !important;
    padding: 1px;
  }
}

.scrollable {
  overflow: 'auto';
  overflow-y: 'scroll';
}

.kimchi-box {
  text-align: center;
  display: block;
  padding: 10px 15px;
  margin: 10px 5px;
  margin-left: -5px;
  border: 2px solid #000000;
  -webkit-box-shadow: 0 0 0 5px, 11px 13px 0 4px black;
  -moz-box-shadow: 0 0 0 5px, 11px 13px 0 4px black;
  box-shadow: 0 0 0 5px, 11px 13px 0 4px black;
}

.kimchi-list {
  text-align: left;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.kimchi-list ul li {
  list-style-type: none;
}

.kimchi-list div:hover {
  list-style-type: none;
  background-color: black;
}

.kimchi-purple-mark {
  color: #f5f;
  word-wrap: break-word;
}

.kimchi-h1 {
  background-color: #00aaaa;
  color: black !important;
  padding-left: 15px;
}

.stroke-through {
  /* background: lightyellow; */

  text-decoration: underline;
  text-underline-offset: -40%;
  /* <-- this */

  text-decoration-skip-ink: none;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: '';
  left: 0;
  top: 35%;
  right: 0;
  border-top: 3px solid;
  border-color: inherit;

  -webkit-transform: rotate(-40deg);
  -moz-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  -o-transform: rotate(-40deg);
  transform: rotate(-40deg);
}
.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.kimchi-page {
  padding-top: 10px;
}

.markdown ul {
  padding-left: 1.5em
}
