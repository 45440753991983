ul {
    margin-left: 1em;
}

h1 {
    font-size: 1em;
}

h2 {
    font-size: 1em;
    line-height: 1;
}

h3 {
    font-size: 1em;
    color: #ff5;
    background-color: transparent;
    margin: 1em 0 1em 0em;
    text-indent: 0px;
    line-height: 1;
}
p { 
    padding: 0.2em;
}
